import * as React from "react";
import { useEffect, useState, useContext } from "react";
import DataEntry from "../../../components/datagrid/dataEntry";
import UserContext from "../../../contexts/UserContext";
import { getData, postData, putData, deleteData } from "../../../utils/API";
import { validateRequiredAttributes } from "../../../utils/ValidationUtils";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { useLocation } from "react-router-dom";
import useSecurity from "../../../hooks/use-security";

export default function PracticeTMSDevicesGrid() {
  const { menuItems, securityGroupMenus, practice_name } =
    useContext(UserContext);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const title = practice_name;
  const subtitle = "TMS Devices";
  const { pathname } = useLocation();
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });
  const table = "practice_tms_devices";
  const requiredAttributes = [
    "device_manufacturer",
    "device_name",
    "device_model_number",
    "status",
  ];
  const attributeNames = [
    "Manufacturer",
    "Device Name",
    "Device Model Number",
    "Status",
  ];
  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "device_name",
      headerName: "Device Name",
      editable: true,
      cellClassName: "name-column--cell",
      flex: 1,
    },
    {
      field: "device_manufacturer",
      headerName: "Manufacturer",
      editable: true,
      flex: 1,
    },
    {
      field: "device_model_number",
      headerName: "Model",
      editable: true,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      editable: true,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: ["Active", "Inactive"],
      defaultValueGetter: () => "Active",
      flex: 1,
    },
  ];

  const createRowData = (rows) => {
    // IS THIS REDUNDANT, ITS ALSO IN DefaultToolBar
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      device_manufacturer: "",
      device_model_number: "",
      name: "",
      year: null,
      description: "",
      status: "Active",
    };
  };

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  async function validateRow(newRow, oldRow) {
    try {
      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      return newRow;
    } catch (error) {
      throw error;
    }
  }

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      if (
        row.model_number === "" ||
        row.model_number === undefined ||
        row.model_number === null
      ) {
        row.model_number = row.name;
      }
      if (row.isNew) {
        const rowToSave = { ...row };
        // Delete the id that was generated when row was created
        delete rowToSave.id;
        rowToSave["active"] = "Active";
        const data = await postData(table, rowToSave);
        // Add the id returned from the database
        rowToSave.id = data.data.id;
        setRows(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
        return rowToSave;
      } else {
        await putData(table, row);
        setRows(oldRows.map((r) => (r.id === id ? { ...row } : r)));
        return row;
      }
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  async function deleteRow(id, row, oldRows) {
    const body = {
      id: row.id,
    };

    try {
      await deleteData(table, body);
      setRows(oldRows.filter((r) => r.id !== id));
      return "Deleted";
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  const getRows = async () => {
    setLoading(true);
    try {
      const queryParam = {
        deleted: false,
      };

      const data = await getData(table, queryParam);
      setRows(data);
    } catch (error) {
      throw error;
    }
    setLoading(false);
  };

  const initialFetch = async () => {
    try {
      await Promise.all([getRows()]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <DataEntry
      title={title}
      subtitle={subtitle}
      columns={columns}
      rows={rows}
      uniqueKey="PracticeTMSDevicesGrid"
      onValidateRow={validateRow}
      onSaveRow={saveRow}
      onDeleteRow={deleteRow}
      createRowData={createRowData}
      loading={loading}
      hideAddIcon={!canCreate}
      disableEdit={!canUpdate}
      disableDelete={!canDelete}
    />
  );
}
