// practice_episodes/index.jsx

import * as React from 'react';
import '../../../index.css';
import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../../../contexts/UserContext';
import { getData, postData, putData } from '../../../utils/API';
import { typeOfDate } from '../../../utils/ValidationUtils';
import ShowAlert from '../../../utils/ShowAlert';
import { useNotificationHandling } from '../../../utils/NotificationHandling';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import EpisodeForm from '../../patientData/episodes/episodeForm';
import DataEntryForm from '../../../components/datagrid/dataEntryForm';
import useSecurity from '../../../hooks/use-security';

const subtitle = 'TMS Episodes of Care';
const table = 'episodes_of_care';

export default function PracticeTMSEpisodesDataGrid() {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();
  const navigate = useNavigate();
  const { practice_name, menuItems, securityGroupMenus } =
    useContext(UserContext);
  const title = practice_name;
  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);
  const [officeNames, setOfficeNames] = useState([]);
  const [practiceOfficeTreatments, setPracticeOfficeTreatments] = useState([]);
  const [officeObjects, setOfficeObjects] = useState([]);
  const [practitionerObjects, setPractitionerObjects] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRow, setRow] = useState(null);
  const isUpdate = Boolean(selectedRow);
  const [patients, setPatients] = useState([]);
  const [treatmentStatus, setTreatmentStatus] = useState([]);
  const [treatmentStatusReasons, setTreatmentStatusReasons] = useState([]);
  const { pathname } = useLocation();
  const { canCreate, canUpdate, canDelete } = useSecurity({
    menuItems,
    pathname,
    securityGroupMenus,
  });

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  // Handle row click
  const handleRowClick = (params) => {
    const currentRow = { ...params.row };

    const startDate = typeOfDate(currentRow.start_date, 'string');
    currentRow.start_date = startDate;

    if (
      typeof currentRow.end_date !== 'undefined' &&
      currentRow.end_date !== null
    ) {
      const endDate = typeOfDate(currentRow.end_date, 'string');
      currentRow.end_date = endDate;
    } else {
      currentRow.endDate = ' ';
    }

    currentRow.lastName = currentRow.last_name;
    currentRow.firstName = currentRow.first_name;

    navigate('/patient/episode/dashboard', {
      state: { episodeOfCare: currentRow },
    });
  };

  const columns = [
    {
      field: 'full_name',
      headerName: 'Patient',
      type: 'singleSelect',
      editable: false,
      flex: 0.5,
      defaultValue: '',
      valueOptions: [...new Set(rows.map((row) => row.full_name))],
    },
    {
      field: 'office_name',
      headerName: 'Office',
      type: 'singleSelect',
      valueOptions: officeNames,
      flex: 0.75,
      defaultValue: '',
    },
    {
      field: 'practitioner_full_name',
      headerName: 'Practitioner',
      type: 'singleSelect',
      valueOptions: practitionerObjects.map(
        (practitioner) => practitioner.name
      ),
      flex: 0.75,
      defaultValue: '',
    },
    {
      field: 'status',
      headerName: 'Treatment Status',
      type: 'singleSelect',
      valueOptions: treatmentStatus
        .filter((status) =>
          rows.find((row) => row.status === status.treatment_status)
        )
        .map((status) => status.treatment_status),
      flex: 1,
    },
    {
      field: 'treatment_status_reason',
      headerName: 'Status Reason',
      type: 'singleSelect',
      valueOptions: treatmentStatusReasons
        .filter((reason) =>
          rows.find(
            (row) =>
              row.treatment_status_reason === reason.treatment_status_reason
          )
        )
        .map((reason) => reason.treatment_status_reason),
      flex: 0.75,
    },
    {
      field: 'start_date',
      headerName: 'Start',
      type: 'date',
      width: 100,
      valueGetter: (params) => {
        // On the first render start_date is a string, on subsequent renders its a date object
        const startDate = params.row.start_date;
        const startDateObject = typeOfDate(startDate, 'object');
        return startDateObject;
      },
    },
    {
      field: 'end_date',
      headerName: 'End',
      type: 'date',
      width: 100,
      valueGetter: (params) => {
        // BE CAREFUL CHANGING THIS - WAS DIFFICULT TO MAKE WORK
        // ACROSS ALL USE CASES
        const endDate = params.row.end_date;
        if (endDate instanceof Date) {
          if (!isNaN(Date.parse(endDate))) {
            return endDate;
          } else {
            return '';
          }
        }

        if (typeof endDate === 'string') {
          const endDateString = endDate.replace(/-/g, '/');
          const endDateObject = new Date(endDateString);
          if (!isNaN(endDateObject.getTime())) {
            return endDateObject;
          } else {
            return;
          }
        }
      },
    },
  ];

  const handleModalClose = () => {
    setIsOpenModal(false);
    if (selectedRow) {
      setRow(null);
    }
  };

  const handleOnSubmit = async (values) => {
    const rowToSave = {
      patient_id: values.patient_id,
      office_id: values.office_id,
      treatment_status_id: values.treatment_status_id,
      treatment_type_id: 10000,
      start_date: typeOfDate(values.start_date, 'string'),
      end_date: typeOfDate(values.end_date, 'string') || null,
      treatment_status_reason_id: values.treatment_status_reason_id || null,
      practitioner_id: values.practitioner_id || null,
      age: null,
      deleted: false,
    };

    handleModalClose();

    try {
      setLoading(true);

      if (!isUpdate) {
        await postData(table, rowToSave);
        await getEpisodeOfCare();
      } else {
        const status = treatmentStatus.find(
          (s) => s.treatment_status_id === rowToSave.treatment_status_id
        );

        if (status.treatment_status === 'Completed') {
          //status === Completed
          const treatmentSessions = await getData(
            'episode_tms_treatment_sessions',
            {
              episode_of_care_id: selectedRow.id,
              deleted: false,
            }
          );
          if (treatmentSessions.length < 30) {
            const customError = new Error();
            customError.name = 'Validation Error';
            customError.message = `Unable to mark as Complete.  Please set up at least 30 treatment sessions before changing the status to Complete.`;
            handleErrorNotification(customError);
            setLoading(false);
            return;
          }

          let testScores = await getData('episode_questionnaire_scores', {
            episode_of_care_id: selectedRow.id,
          });
          testScores = testScores.filter(
            (t) =>
              t.questionnaire_score_interval_name === 'Baseline' ||
              t.questionnaire_score_interval_name === 'Final'
          );

          if (testScores.length < 2) {
            const customError = new Error();
            customError.name = 'Validation Error';
            customError.message = `Unable to mark as Complete.  Please set up a baseline and finial test scores.`;
            handleErrorNotification(customError);
            setLoading(false);
            return;
          }
        }

        await putData(table, { ...rowToSave, id: selectedRow.id });
        await getEpisodeOfCare();
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      handleErrorNotification(error);
    }
  };

  const getEpisodeOfCare = async function () {
    try {
      const data = await getData('episodes_of_care', {
        deleted: false,
      });
      setRows(data);
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  async function episodesTestScoreExists(row) {
    try {
      const query_params = { episode_of_care_id: row.id, deleted: false };
      const result = await getData(
        'episode_questionnaire_scores',
        query_params
      );
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      handleErrorNotification(error);
      return null;
    }
  }

  async function episodesTreatmentPlansExists(row) {
    try {
      const query_params = { episode_of_care_id: row.id, deleted: false };
      const result = await getData('episode_tms_treatment_plans', query_params);
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      handleErrorNotification(error);
      return null;
    }
  }

  async function episodesTreatmentSessionExists(row) {
    try {
      const query_params = { episode_of_care_id: row.id, deleted: false };
      const result = await getData(
        'episode_tms_treatment_sessions',
        query_params
      );
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      handleErrorNotification(error);
      return null;
    }
  }

  async function deleteRow(id, row, oldRows) {
    const treatmentPlansExists = await episodesTreatmentPlansExists(row);
    if (treatmentPlansExists === null) {
      return;
    }
    if (treatmentPlansExists) {
      const customError = new Error();
      customError.name = 'Delete Error';
      customError.message = `This Episode of Care has Treatment Plans. Delete the Treatment Plans before deleting the Episode of Care.`;
      handleErrorNotification(customError);
      return;
    }

    // Need to create an error object for this condition
    const treatmentSessionExists = await episodesTreatmentSessionExists(row);
    if (treatmentSessionExists === null) {
      return;
    }
    if (treatmentSessionExists) {
      const customError = new Error();
      customError.name = 'Delete Error';
      customError.message = `This Episode of Care has Treatment Sessions. Delete the Treatment Sessions before deleting the Episode of Care.`;
      handleErrorNotification(customError);
      return;
    }

    const testScoreExists = await episodesTestScoreExists(row);
    if (testScoreExists === null) {
      return;
    }
    if (testScoreExists) {
      const customError = new Error();
      customError.name = 'Delete Error';
      customError.message = `This Episode of Care has test scores. Delete the test scores before deleting the Episode of Care.`;
      handleErrorNotification(customError);
      return;
    }

    const rowToSave = { ...row, deleted: true };
    delete rowToSave.start_date;
    delete rowToSave.end_date;

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return 'Deleted';
    } catch (error) {
      handleErrorNotification(error);
    }
  }

  const handleOpenModal = (id) => {
    // Prevent opening the modal if data is still loading
    if (loading) {
      return; // Do nothing if data is still being fetched
    }

    if (
      !officeObjects ||
      officeObjects.length === 0 ||
      !practitionerObjects ||
      practitionerObjects.length === 0 ||
      !practiceOfficeTreatments ||
      practiceOfficeTreatments.length === 0 ||
      !patients ||
      patients.length === 0 ||
      !treatmentStatus ||
      treatmentStatus.length === 0 ||
      !treatmentStatusReasons ||
      treatmentStatusReasons.length === 0
    ) {
      const customError = new Error();
      customError.name = 'Data Error';
      customError.message = `Data could not be retrieved at this time. Please check your connection and try again, or contact support if the issue persists.`;
      handleErrorNotification(customError);
      return; // Prevent opening the modal
    }

    let selectedRow = null;
    setIsOpenModal(true);
    if (id) {
      selectedRow = rows.find((r) => r.id === id);
      setRow(selectedRow);
    }
  };

  const getPatients = async function () {
    try {
      const data = await getData('practice_patients', {
        deleted: false,
      });
      setPatients(data);
    } catch (error) {
      throw error;
    }
  };

  const getTreatmentStatus = async function () {
    try {
      const data = await getData('practice_treatment_status', {
        deleted: false,
        treatment_type_id: 10000,
      });
      setTreatmentStatus(data);
    } catch (error) {
      throw error;
    }
  };

  const getPracticePractitioners = async function () {
    try {
      const data = await getData('practice_practitioners', {
        deleted: false,
        status: 'Active',
      });
      setPractitionerObjects(data);
      const names = data.map((office) => office.name);
      setOfficeNames(names);
    } catch (error) {
      throw error;
    }
  };

  const getPracticeOfficeTreatments = async function () {
    try {
      const data = await getData('practice_office_treatments', {
        deleted: false,
        status: 'Active',
      });
      setPracticeOfficeTreatments(data);
    } catch (error) {
      throw error;
    }
  };

  const getPracticeOffices = async function () {
    try {
      const data = await getData('practice_offices', {
        deleted: false,
        status: 'Active',
        treatment_type_id: 10000,
      });
      setOfficeObjects(data);
      const names = data.map((office) => office.name);
      setOfficeNames(names);
    } catch (error) {
      throw error;
    }
  };

  const getTreatmentStatusReason = async function () {
    try {
      const data = await getData('practice_treatment_status_reasons', {
        deleted: false,
      });
      setTreatmentStatusReasons(data);
    } catch (error) {
      throw error;
    }
  };

  const initialFetch = async () => {
    try {
      await Promise.all([
        getPatients(),
        getTreatmentStatus(),
        getTreatmentStatusReason(),
        getPracticeOffices(),
        getPracticePractitioners(),
        getEpisodeOfCare(),
        getPracticeOfficeTreatments(),
      ]);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialFetch();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <DataEntryForm
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        filterKey='PracticeEpisodesDataGrid'
        loading={loading}
        onDeleteRow={deleteRow}
        onAdd={handleOpenModal}
        onEdit={handleOpenModal}
        onRowClick={handleRowClick}
        disableAdd={!canCreate}
        disableEdit={!canUpdate}
        disableDelete={!canDelete}
      />

      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '750px',
          },
        }}
        open={isOpenModal}
        onClose={handleModalClose}
      >
        <DialogTitle>{`${
          isUpdate ? 'Edit' : 'Add'
        } TMS Episodes of Care`}</DialogTitle>
        <DialogContent>
          <EpisodeForm
            onCancel={handleModalClose}
            onSubmit={handleOnSubmit}
            selectedEpisode={selectedRow}
            offices={officeObjects}
            practitioners={practitionerObjects}
            practiceOfficeTreatments={practiceOfficeTreatments}
            patients={patients}
            treatmentStatus={treatmentStatus}
            treatmentStatusReasons={treatmentStatusReasons}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
