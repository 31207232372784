// src/App.js
import React, { useState, useEffect, useContext } from 'react';
import { CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import Login from './pages/Login';
import { ColorModeContext, useMode } from './theme';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import AppLayout from './components/appLayout/AppLayout';
import { Box } from '@mui/system';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import AppRoutes from './AppRoutes';
import UserContext from './contexts/UserContext';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';

function App() {
  const [theme, colorMode] = useMode();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const { loading: userLoading } = useContext(UserContext);

  useEffect(() => {
    async function checkAuthentication() {
      try {
        await Auth.currentSession();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
      setLoading(false);
    }

    checkAuthentication();
  }, [loginSuccess]);

  function handleSuccessfulLogin() {
    setLoginSuccess(true); // Toggle the state
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Authenticator.Provider>
          <CssBaseline />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className='app'>
              {loading || userLoading ? (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  height='100vh'
                  width='100%'
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {isAuthenticated ? (
                    <AppLayout>
                      <AppRoutes isAuthenticated />
                    </AppLayout>
                  ) : (
                    <Box className='login-section'>
                      <Login onSuccessfulLogin={handleSuccessfulLogin} />
                    </Box>
                  )}
                </>
              )}
            </div>
          </ErrorBoundary>
        </Authenticator.Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
library.add(fab, fas, far);
